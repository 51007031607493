import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useEnv } from '@src/hooks/useEnv';
import { StyledRecaptchaBox } from '@components/ReCaptchaBox/styles';
import { useValidateReCaptcha } from '@src/api/hooks/auth/useValidateRecaptcha';
import { IReCaptchaActionType } from '@components/ReCaptchaBox/types';

interface ReCaptchaBoxProps {
	widgetId: string;
	action: IReCaptchaActionType;
	name: string;
}

const ReCaptchaBox = ({ widgetId, action, name }: ReCaptchaBoxProps) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const { setValue } = useFormContext();
	const envVars = useEnv();
	const { validateReCaptcha } = useValidateReCaptcha();

	const verifiedCallback = async (token: string) => {
		const isValidToken = await validateReCaptcha({ token, action });

		setValue(name, !!isValidToken);
	};

	useEffect(() => {
		if (!isLoaded) {
			if (window.grecaptcha && !!document.getElementById(widgetId)) {
				window.verifiedCallback = verifiedCallback;

				window.grecaptcha.enterprise.ready(() => {
					window.grecaptcha.enterprise.render(widgetId, {
						sitekey: envVars.base.googleRecaptchaSiteKey,
						action,
						callback: 'verifiedCallback',
					});

					setIsLoaded(true);
				});
			}
		}

		return () => {
			if (window.grecaptcha && isLoaded) {
				window.verifiedCallback = null;
				window.grecaptcha.enterprise.reset();
				setIsLoaded(false);
				window.grecaptcha = null;
			}
		};
	}, [widgetId, action]);

	return <StyledRecaptchaBox id={widgetId} />;
};

export default ReCaptchaBox;
