import { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldValues, UseFormProps } from 'react-hook-form/dist/types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

interface IUseValidatedFormProps<TFieldValues extends FieldValues = FieldValues, TContext = any> {
	schema: Yup.ObjectSchema<any>;
	options?: UseFormProps<TFieldValues, TContext>;
	handleFormSubmit: (data: TFieldValues) => void;
}

interface IValidatedFormProviderProps {
	autocomplete?: 'on' | 'off' | 'none' | 'false';
}

export const useValidatedForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>({
	schema,
	options,
	handleFormSubmit,
}: IUseValidatedFormProps<TFieldValues, TContext>) => {
	const methods = useForm<TFieldValues, TContext>({
		mode: 'all',
		resolver: yupResolver(schema),
		...options,
	});

	const ValidatedFormProvider = ({ autocomplete, children }: PropsWithChildren<IValidatedFormProviderProps>) => (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(handleFormSubmit)} name="form" autoComplete={autocomplete}>
				{children}
			</form>
		</FormProvider>
	);

	return {
		methods,
		ValidatedFormProvider,
	};
};
