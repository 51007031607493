import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { register } from 'swiper/element/bundle';
import { A11y, Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import AuthMediaSliderItem from './AuthMediaSliderItem/AuthMediaSliderItem';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';

import SlideImage1 from '@assets/images/slide-1.png';
import SlideImage2 from '@assets/images/slide-2.png';
import SlideImage3 from '@assets/images/slide-3.png';
import SlideImage4 from '@assets/images/slide-4.png';
import { StyledAuthMediaSlider } from './styles';

register();

export const AuthMediaSlider = () => {
	const swiperRef = useRef<{ slideNext: () => void; slidePrev: () => void }>();
	const { t } = useTranslation();

	return (
		<StyledAuthMediaSlider>
			<Swiper
				modules={[Navigation, Pagination, EffectFade, A11y, Autoplay]}
				onSwiper={swiper => {
					swiperRef.current = swiper;
				}}
				slidesPerView={1}
				effect="fade"
				loop
				pagination={{ clickable: true }}
				autoplay={{ delay: 5000 }}
				speed={1000}
			>
				<SwiperSlide key="slide-1">
					<AuthMediaSliderItem key="slide-1" text={t('auth.media.slide1')} imgSrc={SlideImage1} />
				</SwiperSlide>

				<SwiperSlide key="slide-2">
					<AuthMediaSliderItem key="slide-2" text={t('auth.media.slide2')} imgSrc={SlideImage2} />
				</SwiperSlide>

				<SwiperSlide key="slide-3">
					<AuthMediaSliderItem key="slide-3" text={t('auth.media.slide3')} imgSrc={SlideImage3} />
				</SwiperSlide>

				<SwiperSlide key="slide-4">
					<AuthMediaSliderItem key="slide-4" text={t('auth.media.slide4')} imgSrc={SlideImage4} />
				</SwiperSlide>
			</Swiper>
		</StyledAuthMediaSlider>
	);
};
