import styled from 'styled-components';
import { colorToString } from '@dto/themes/types';
import { device } from '@src/utilities/media';

export const StyledAuthMediaLayoutMediaNotice = styled.div`
	display: flex;
	flex: 1 0 0;
	align-items: center;
	justify-content: space-between;
	padding: 20px 35px;
	background: ${props => colorToString(props.theme.variant.default.color.background.message)};
	font-size: 14px;

	.svg-logo-dertour-international {
		width: 102px;
		height: 40px;
	}

	.svg-logo-clevertour {
		width: 185px;
		height: 24px;
	}

	@media ${device.tablet} {
		gap: 10px;
		flex-direction: column;
		justify-content: center;
		padding: 15px 20px;
		text-align: center;

		.svg-logo-dertour-international {
			width: auto;
			height: 32px;
		}

		.svg-logo-clevertour {
			width: auto;
			height: 16px;
		}
	}
`;

export const StyledAuthMediaLayoutBrands = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;

	@media ${device.tablet} {
		gap: 16px;
	}
`;
