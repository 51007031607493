import { forwardRef, HTMLInputTypeAttribute, InputHTMLAttributes, useState } from 'react';
import { useTheme } from 'styled-components';

import { IconValue } from '@dto/themes/theme/icon';
import { ReactComponent as IconEye } from '@assets/svg/icon-eye.svg';
import { ReactComponent as IconEyeOff } from '@assets/svg/icon-eye-off.svg';
import {
	StyledBaseInput,
	StyledBaseInputContainer,
	StyledBaseInputControl,
	StyledBaseInputError,
	StyledBaseInputIcon,
	StyledBaseInputLabel,
	StyledBaseInputRightIcon,
} from './styles';

interface BaseInputProps extends InputHTMLAttributes<HTMLInputElement> {
	id: string;
	label?: string;
	errorText?: string;
	icon?: IconValue;
	autocomplete?: 'on' | 'off' | 'none' | 'false';
	isTextarea?: boolean;
}

type InputRef = HTMLInputElement;

const BaseInput = forwardRef<InputRef, BaseInputProps>(
	({ id, label, errorText, icon, type, autocomplete, isTextarea, ...rest }, ref) => {
		const theme = useTheme();
		const { exclamationMarkFilled: IconExclamationBadge } = theme.icon;
		const IconComponent = !!icon && theme.icon[icon];
		const hasError = !!errorText;
		const [inputType, setInputType] = useState<HTMLInputTypeAttribute | undefined>(type);

		return (
			<StyledBaseInputControl>
				{label && (
					<StyledBaseInputLabel htmlFor={id} data-testid={`base-input-label-${id}`} hasError={hasError}>
						{label}
					</StyledBaseInputLabel>
				)}

				<StyledBaseInputContainer>
					{!!IconComponent && (
						<StyledBaseInputIcon>
							<IconComponent />
						</StyledBaseInputIcon>
					)}

					<StyledBaseInput
						as={isTextarea ? 'textarea' : 'input'}
						id={id}
						{...rest}
						type={inputType}
						ref={ref}
						value={rest.value}
						onChange={rest.onChange}
						hasError={hasError}
						hasIcon={!!IconComponent}
						autoComplete={autocomplete}
						isTextarea={isTextarea}
						data-testid={`base-input-${id}`}
					/>

					{type === 'password' && (
						<StyledBaseInputRightIcon
							offsetX={hasError ? 30 : 0}
							onClick={() => setInputType(old => (old === 'password' ? 'text' : 'password'))}
						>
							{inputType === 'text' ? <IconEyeOff /> : <IconEye />}
						</StyledBaseInputRightIcon>
					)}

					{hasError && (
						<StyledBaseInputRightIcon hasError={hasError} forTextarea={isTextarea}>
							<IconExclamationBadge />
						</StyledBaseInputRightIcon>
					)}
				</StyledBaseInputContainer>

				{errorText && <StyledBaseInputError role="alert">{errorText}</StyledBaseInputError>}
			</StyledBaseInputControl>
		);
	},
);

export default BaseInput;
