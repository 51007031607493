import { lazy } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import {
	ILocationNotificationState,
	IUseNotificationStateValue,
	Notifications,
	NotificationType,
} from '@pages/Notification/types';
import { useNavigateWithQueryParamsPersistence } from '@src/hooks/useNavigateWithQueryParamsPersistence';

export const useNotificationState = (): IUseNotificationStateValue => {
	const location = useLocation();
	return {
		state: location.state as ILocationNotificationState,
		location,
	};
};

export const useNotification = () => {
	const { client } = useRoutingContext();
	const { state, location } = useNotificationState();

	return {
		location,
		NotificationComp: lazy(() => import(`./components/${state.component}.tsx`)),
		isAccessible: !!state?.component && Object.keys(Notifications).includes(state?.component),
		NavigateOut: () => <Navigate to={state?.from?.pathname ?? client.home()} replace />,
	};
};

interface IUseNotificationNavigate {
	navigateOut: (pathname?: string) => void;
	notificationNavigate: (component: NotificationType) => void;
}

export const useNotificationNavigate = (): IUseNotificationNavigate => {
	const { client } = useRoutingContext();
	const { navigate } = useNavigateWithQueryParamsPersistence();
	const location = useLocation();

	const navigateOut = (pathname?: string) => navigate(pathname ?? location.state?.from?.pathname ?? client.home());

	const notificationNavigate = (component: NotificationType) =>
		navigate(client.notification(), {
			state: {
				component,
				from: location,
			},
		});

	return {
		navigateOut,
		notificationNavigate,
	};
};
