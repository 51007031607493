import { memo, useState } from 'react';

import { IToast } from '@ui/Toast/types';
import { useTimeout } from '@src/hooks/useTimeout';
import { useToastContext } from '@ui/Toast/ToastContext';
import { useUpdateEffect } from '@src/hooks/useUpdateEffect';
import AlertBlock from '@ui/AlertBlock/AlertBlock';
import { StyledToast } from '@ui/Toast/styles';

const defaultDuration = 3000;

const Toast = ({ id, title, content, duration, type, autoClose = true }: IToast) => {
	const [delay, setDelay] = useState(duration ?? defaultDuration);
	const { removeToast } = useToastContext();

	useUpdateEffect(() => {
		setDelay(duration ?? defaultDuration);
	}, [duration]);

	useTimeout(() => removeToast(id), autoClose ? delay : null);

	return (
		<StyledToast>
			<AlertBlock variant={type} title={title} description={content} onClose={() => removeToast(id)} />
		</StyledToast>
	);
};

export default memo(Toast);
