import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RightOfWithdrawalResponse, TermsOfUseResponse } from '@src/types/legal';
import { DataVersionModalType } from '@pages/Legal/LegalConsent/useLegalConsentModal';
import Spinner from '@ui/Spinner/Spinner';
import BaseButton from '@ui/BaseButton/BaseButton';
import BaseModal from '@ui/BaseModal/BaseModal';

import { StyledModalActions, StyledModalBody } from '@ui/BaseModal/styles';
import { StyledLegalConsentContent } from '@ui/StyledLegal/styles';

interface LegalConsentModalProps {
	onClose: () => void;
	onConfirm: () => void;
	modalData: TermsOfUseResponse | RightOfWithdrawalResponse;
	isLoading?: boolean;
	modalDataVersion: DataVersionModalType;
}
const LegalConsentModal = ({
	modalData,
	modalDataVersion,
	onClose,
	onConfirm,
	isLoading = false,
}: LegalConsentModalProps) => {
	const { t } = useTranslation();

	const dataLinkId = useMemo(() => {
		if (modalDataVersion === 'rightOfWithdrawalVersion') {
			return 'right_of_withdrawal_update_accept';
		}

		if (modalDataVersion === 'termsOfUseVersion') {
			return 'terms_update_accept';
		}

		return '';
	}, [modalDataVersion]);

	return (
		<BaseModal isOpen={!!modalData} onClose={onClose} title={modalData?.title} contentWidth="100%">
			<StyledModalBody>
				{!!modalData?.section && (
					<Fragment>
						{modalData?.section?.map(section => (
							<StyledLegalConsentContent key={section.section_headline}>
								<h3>{section.section_headline}</h3>
								<div dangerouslySetInnerHTML={{ __html: section.section_content }} />
							</StyledLegalConsentContent>
						))}
					</Fragment>
				)}

				{!!modalData?.rich_text_editor && (
					<StyledLegalConsentContent>
						<div dangerouslySetInnerHTML={{ __html: modalData.rich_text_editor }} />
					</StyledLegalConsentContent>
				)}
			</StyledModalBody>

			<StyledModalActions>
				<BaseButton type="button" onClick={onConfirm} data-linkid={dataLinkId} data-linktype="onPageNavigation">
					{isLoading ? <Spinner width={20} height={20} isWhite /> : t('common.accept')}
				</BaseButton>
			</StyledModalActions>
		</BaseModal>
	);
};

export default LegalConsentModal;
