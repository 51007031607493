import { ChangeEvent, forwardRef, ReactNode } from 'react';

import { ReactComponent as IconCheck } from '@assets/svg/icon-check.svg';
import { StyledCheckbox, StyledCheckboxIcon, StyledCheckboxLabel, StyledCheckboxText } from '@ui/BaseCheckbox/styles';
import { StyledBaseInputError } from '@ui/BaseInput/styles';

export interface BaseCheckboxProps {
	id: string;
	name?: string;
	label?: string | ReactNode;
	clickableText?: boolean;
	errorText?: string;
	checked?: boolean;
	disabled?: boolean;
	onChange?: (value: boolean) => void;
}

type CheckboxRef = HTMLInputElement;

const BaseCheckbox = forwardRef<CheckboxRef, BaseCheckboxProps>(
	({ id, name, label, errorText, checked = false, disabled = false, onChange, clickableText = false }, ref) => {
		const hasError = !!errorText;
		const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
			onChange && onChange(event.target.checked);
		};

		return (
			<StyledCheckbox clickableText={clickableText}>
				<input
					type="checkbox"
					ref={ref}
					id={id}
					name={name}
					checked={checked}
					disabled={disabled}
					onChange={handleChange}
					data-testid={`checkbox-${id}`}
				/>

				<StyledCheckboxLabel htmlFor={id}>
					<StyledCheckboxIcon hasError={hasError}>
						<IconCheck />
					</StyledCheckboxIcon>

					{!clickableText && (
						<StyledCheckboxText>
							{label && label}

							{hasError && <StyledBaseInputError role="alert">{errorText}</StyledBaseInputError>}
						</StyledCheckboxText>
					)}
				</StyledCheckboxLabel>

				{clickableText && <StyledCheckboxText>{label && label}</StyledCheckboxText>}
			</StyledCheckbox>
		);
	},
);

export default BaseCheckbox;
