import styled from 'styled-components';

import { colorToString } from '@dto/themes/types/color';
import { spacingToString } from '@dto/themes/types';

export const StyledButtonBack = styled.button`
	display: inline-flex;
	gap: ${props => spacingToString(props.theme.variant.default.spacing.s1.default)};
	align-items: center;
	padding: 2px;
	border: none;
	background: transparent;
	font-size: 14px;
	font-weight: 600;
	color: ${props => colorToString(props.theme.variant.default.color.label.interactive.primary)};
	cursor: pointer;
	appearance: none;

	svg {
		transition: transform 0.3s;
	}

	&:hover {
		svg {
			transform: translateX(-2px);
		}
	}
`;
