import styled, { css } from 'styled-components';
import { colorToString } from '@dto/themes/types/color';

export const StyledBaseInputControl = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const StyledBaseInputLabel = styled.label.attrs((props: { hasError: boolean }) => props)`
	width: 100%;
	display: block;
	margin-bottom: 8px;
	font-size: 12px;
	line-height: 1.33;
	color: ${props =>
		props.hasError
			? colorToString(props.theme.variant.default.color.text.critical)
			: colorToString(props.theme.variant.default.color.label.standard)};
`;

export const StyledBaseInput = styled.input<{ hasError: boolean; hasIcon?: boolean; isTextarea?: boolean }>`
	appearance: none;
	font: inherit;
	width: 100%;
	border: 1px solid
		${props =>
			props.hasError
				? colorToString(props.theme.variant.default.color.text.critical)
				: colorToString(props.theme.variant.default.color.border.interactive.secondary)};
	border-radius: 5px;
	background: ${props => colorToString(props.theme.variant.default.color.background.standard)};
	padding: ${props => (props.isTextarea ? '12px' : '0')} 16px;
	height: ${props => (props.isTextarea ? '120px' : '48px')};
	color: ${props => (props.hasError ? colorToString(props.theme.variant.default.color.text.critical) : 'inherit')};
	resize: none;
	transition: border-color 0.3s;

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&:focus {
		outline: 0;
		border-color: ${props =>
			props.hasError
				? colorToString(props.theme.variant.default.color.border.critical)
				: colorToString(props.theme.variant.default.color.focus.ring)};
	}

	&::placeholder {
		opacity: 1;
		color: ${props => colorToString(props.theme.variant.default.color.text.description)};
	}

	&:-webkit-autofill {
		-webkit-background-clip: text;
	}

	&::-webkit-credentials-auto-fill-button,
	&::-webkit-caps-lock-indicator {
		visibility: hidden;
		display: none !important;
		pointer-events: none;
	}

	&::-ms-reveal,
	&::-ms-clear {
		display: none !important;
		visibility: hidden;
		pointer-events: none;
	}

	${props =>
		props.hasIcon &&
		css`
			padding-left: 40px;
		`}
`;

export const StyledBaseInputError = styled.p`
	width: 100%;
	font-size: 14px;
	color: ${props => colorToString(props.theme.variant.default.color.text.critical)};
	padding-top: 8px;
`;

export const StyledBaseInputContainer = styled.div`
	width: 100%;
	position: relative;
`;

export const StyledBaseInputIcon = styled.span`
	position: absolute;
	top: 50%;
	left: 17px;
	margin-top: -8px;
	font-size: 16px;
	line-height: 0;
	color: ${props => colorToString(props.theme.variant.default.color.border.interactive.secondary)};
`;

export const StyledBaseInputRightIcon = styled.span<{ hasError?: boolean; offsetX?: number; forTextarea?: boolean }>`
	position: absolute;
	top: ${props => (props.forTextarea ? '22px' : '50%')};
	right: ${props => (props.offsetX ? `${props.offsetX}px` : '10px')};
	margin-top: -8px;
	color: ${props =>
		props.hasError
			? colorToString(props.theme.variant.default.color.text.critical)
			: colorToString(props.theme.variant.default.color.icon.interactive.secondary)};

	${props =>
		!!props.onClick &&
		css`
			cursor: pointer;
		`}
`;
