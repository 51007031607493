import { ReactNode } from 'react';
import { useTheme } from 'styled-components';

import {
	StyledAlertBlock,
	StyledAlertBlockButton,
	StyledAlertBlockContent,
	StyledAlertBlockDescription,
	StyledAlertBlockIcon,
	StyledAlertBlockTitle,
} from '@ui/AlertBlock/styles';

export type IAlertVariant = 'error' | 'info' | 'success';

interface AlertBlockProps {
	variant?: IAlertVariant;
	title?: string;
	description: string | ReactNode;
	onClose?: () => void;
}
const AlertBlock = ({ variant = 'error', title, description, onClose }: AlertBlockProps) => {
	const theme = useTheme();
	const {
		close: IconClose,
		checkFilled: IconCheckFilled,
		exclamationMarkFilled: IconExclamationMark,
		warning: IconWarning,
	} = theme.icon;

	const getIconByVariant = () => {
		switch (variant) {
			case 'success':
				return <IconCheckFilled />;
			case 'info':
				return <IconExclamationMark />;
			default:
				return <IconWarning />;
		}
	};

	return (
		<StyledAlertBlock variant={variant} data-testid="alert-block">
			{onClose && (
				<StyledAlertBlockButton onClick={onClose} data-testid="alert-block-close-btn">
					<IconClose width={20} height={20} />
				</StyledAlertBlockButton>
			)}

			<StyledAlertBlockIcon>{getIconByVariant()}</StyledAlertBlockIcon>

			<StyledAlertBlockContent>
				{title && <StyledAlertBlockTitle data-testid="alert-block-title">{title}</StyledAlertBlockTitle>}
				<StyledAlertBlockDescription data-testid="alert-block-description">{description}</StyledAlertBlockDescription>
			</StyledAlertBlockContent>
		</StyledAlertBlock>
	);
};

export default AlertBlock;
