import { colorToString } from '@dto/themes/types';
import styled from 'styled-components';
import { device } from '@src/utilities/media';

export const StyledMediaRiboon = styled.div`
	background: #fc5f76;
	min-height: 60px;
	font-size: 14px;
	color: ${props => colorToString(props.theme.variant.default.color.background.standard)};
	padding: 0 20px;
	display: inline-flex;
	align-items: center;
	position: relative;
	margin-right: 15px;

	@media ${device.mobile} {
		padding: 0 10px;
		font-size: 14px;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		left: 100%;
		width: 0;
		height: 0;
		border-style: solid;
	}

	&:before {
		top: 0;
		border-width: 30px 15px 0 0;
		border-color: #fc5f76 transparent transparent transparent;
	}

	&:after {
		bottom: 0;
		border-width: 30px 0 0 15px;
		border-color: transparent transparent transparent #fc5f76;
	}

	a {
		color: inherit;

		&:hover {
			text-decoration: none;
		}
	}
`;

export const StyledMediaRiboonIcon = styled.span`
	font-size: 20px;
	margin-right: 20px;

	@media ${device.mobile} {
		margin-right: 10px;
		font-size: 16px;
	}
`;
export const StyledMediaRiboonEntry = styled.div`
	h3 {
		font-size: 20px;
		font-weight: 600;

		@media ${device.tablet} {
			font-size: 18px;
		}

		@media ${device.mobile} {
			font-size: 16px;
		}
	}
`;
