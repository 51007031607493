import { useQuery } from '@tanstack/react-query';

import { get } from '@src/api/internalApi';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import { useParams } from 'react-router';
import { BaseError } from '@src/api/errors';

export const useSignupVerification = () => {
	const params = useParams();
	const { api } = useRoutingContext();

	const { isLoading, data, error, isError } = useQuery(['signup-verification'], () =>
		get(
			api.signupVerification({
				tokenId: params.tokenId,
			}),
		),
	);

	const axiosError = error as BaseError;
	const isErrorNotFound =
		axiosError?.response?.status === 404 && axiosError.response.data.context === 'ResourceNotFoundException';
	const isErrorExpiredToken = !!axiosError
		? axiosError.response?.data.status === 410 && axiosError.response?.data.context === 'TokenExpiredException'
		: false;

	return {
		isLoading,
		data,
		error,
		isErrorExpiredToken,
		isErrorNotFound,
		isError,
	};
};
