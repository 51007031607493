import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import i18next from 'i18next';

// export const redirectUrl = new URL(window.location.href).searchParams.get('redirectUrl');

export const internalHttp = axios.create({
	baseURL: '/api',
});

export const get = async <T>(endpoint: string, config?: AxiosRequestConfig) => {
	const res = await internalHttp.get(endpoint, config);

	return res.data as T;
};

export const post = async <T>(endpoint: string, data?: any, config?: AxiosRequestConfig) => {
	const res = await internalHttp.post(endpoint, data, config);
	return res.data as T;
};

export const put = async <T>(endpoint: string, data?: any, config?: AxiosRequestConfig) => {
	const res = await internalHttp.put(endpoint, data, config);
	return res.data as T;
};

export const patch = async <T>(endpoint: string, data?: any, config?: AxiosRequestConfig) => {
	const res = await internalHttp.patch(endpoint, data, config);
	return res.data as T;
};

export const remove = async <T>(endpoint: string, config?: AxiosRequestConfig) => {
	const res = await internalHttp.delete(endpoint, config);
	return res.data as T;
};

internalHttp.interceptors.response.use(
	response => response,
	async error => {
		throw new AxiosError(error.message, error.code, error.config, error.request, {
			...error.response,
			data: {
				...error.response?.data,
				message: i18next.t(`errors.${error.response?.data?.context ?? 'InternalServerErrorException'}`),
			},
		});
	},
);
