import { ReactNode } from 'react';

import {
	StyledAuthSliderItem,
	StyledAuthSliderItemEntry,
	StyledAuthSliderItemHead,
	StyledAuthSliderItemImage,
} from './styles';

interface AuthMediaSliderItemProps {
	text: ReactNode;
	imgSrc: string;
}

const AuthMediaSliderItem = ({ text, imgSrc }: AuthMediaSliderItemProps) => {
	return (
		<StyledAuthSliderItem>
			<StyledAuthSliderItemHead>
				<StyledAuthSliderItemEntry>
					<p>{text}</p>
				</StyledAuthSliderItemEntry>
			</StyledAuthSliderItemHead>

			<StyledAuthSliderItemImage>
				<img src={imgSrc} alt="slide image" />
			</StyledAuthSliderItemImage>
		</StyledAuthSliderItem>
	);
};

export default AuthMediaSliderItem;
