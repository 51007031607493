import { ReactNode } from 'react';
import { StyledMediaRiboon, StyledMediaRiboonEntry, StyledMediaRiboonIcon } from '@components/MediaRibbon/styles';

interface MediaRiboonProps {
	icon?: ReactNode;
	title: ReactNode;
	text: ReactNode;
}

const MediaRibbon = ({ icon, title, text }: MediaRiboonProps) => {
	return (
		<StyledMediaRiboon>
			{!!icon && <StyledMediaRiboonIcon dangerouslySetInnerHTML={{ __html: icon }} />}
			<StyledMediaRiboonEntry>
				{!!title && <h3>{title}</h3>}
				<p>{text}</p>
			</StyledMediaRiboonEntry>
		</StyledMediaRiboon>
	);
};

export default MediaRibbon;
