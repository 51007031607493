import { InvalidateOptions, QueryKey, useQueryClient } from '@tanstack/react-query';

const useInvalidateQueries = () => {
	const queryClient = useQueryClient();

	const invalidate = async (queryKey: QueryKey, options?: InvalidateOptions) => {
		await queryClient.invalidateQueries({ queryKey }, { ...options });
	};

	return {
		invalidate,
	};
};

export default useInvalidateQueries;
