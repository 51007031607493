import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

import { post } from '@src/api/internalApi';

import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import { ILoginAxiosResponse } from '@src/types/user';
import { ILoginAxiosRequest } from '@src/types/auth';
import { BaseError } from '@src/api/errors';
import { useAdobeAnalytics } from '@src/contexts/AdobeAnalytics/AdobeAnalyticsContext';
import { useNavigateWithQueryParamsPersistence } from '@src/hooks/useNavigateWithQueryParamsPersistence';

export const useLogin = () => {
	const { api, client } = useRoutingContext();
	const { dataLayer } = useAdobeAnalytics();
	const { navigate } = useNavigateWithQueryParamsPersistence();

	const {
		isLoading,
		data,
		error,
		mutateAsync: login,
	} = useMutation(['me'], (data: ILoginAxiosRequest) => post<ILoginAxiosResponse>(api.login(), data), {
		onError: error => {
			if (isAxiosError(error)) {
				const axiosError = error as BaseError;
				if (axiosError?.response?.data.context === 'PasswordResetRequiredException') {
					navigate(client.forgotPassword());
				}

				if (error?.response?.status === 401) {
					dataLayer.error({
						context: 'login',
						reason: error.response.data.message,
						name: error.response.data.message,
					});
				}
			}
		},
	});

	return {
		isLoading,
		data,
		error,
		login,
	};
};
