import styled, { css } from 'styled-components';
import { colorToString } from '@dto/themes/types/color';

import { StyledButton } from '@ui/BaseButton/styles';
import { StyledCheckboxIcon, StyledCheckboxText } from '@ui/BaseCheckbox/styles';
import { Link } from 'react-router-dom';
import { device } from '@src/utilities/media';
import { StyledBrandLogos } from '@components/BrandLogos/styles';

export const StyledAuth = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 100%;
	background: linear-gradient(201deg, rgba(228, 0, 40, 0.26) 0%, rgba(255, 253, 251, 1) 43%);
	padding: 20px 0;

	@media ${device.mobile} {
		padding: 50px 24px;
	}
`;

export const StyledAuthContent = styled.div<{ wide?: boolean }>`
	max-width: 500px;
	width: 100%;
	padding: 32px 48px 48px;
	box-shadow: 0px 8px 16px 0px rgba(39, 28, 26, 0.16), 0px 2px 4px 0px rgba(39, 28, 26, 0.04);
	background: var(--background-bright, #fff);
	border-radius: 8px;

	@media ${device.mobile} {
		padding: 32px 16px;
	}
`;

interface IStyledAuthTitleProps {
	textAlign?: 'left' | 'right' | 'center';
	size?: 'small';
}

export const StyledAuthTitle = styled.h1<IStyledAuthTitleProps>`
	margin-bottom: 32px;
	font: 500 48px/1.25 'Roboto Slab', sans-serif;
	text-align: ${props => (props?.textAlign ? props.textAlign : 'left')};
	color: ${props => colorToString(props.theme.variant.default.color.text.headline)};

	@media ${device.tablet} {
		font-size: 36px;
	}

	${props =>
		props?.size &&
		css`
			font-size: 24px;

			@media ${device.tablet} {
				font-size: 24px;
				margin-bottom: 24px;
			}
		`}
`;

export const StyledAuthEntry = styled.p`
	font-size: 18px;
	color: ${props => colorToString(props.theme.variant.default.color.text.headline)};
`;

export const StyledAuthHead = styled.div`
	margin-bottom: 25px;
	text-align: center;

	${StyledBrandLogos} {
		justify-content: center;
		margin-bottom: 32px;
	}
`;

export const StyledAuthBody = styled.div``;

export const StyledAuthImage = styled.img`
	margin-bottom: 16px;
`;

export const StyledAuthImageHolder = styled.div`
	margin-bottom: 16px;
`;

export const StyledAuthItem = styled.div`
	text-align: left;

	& + & {
		margin-top: 24px;
	}
`;

export const StyledAuthActions = styled.div`
	margin-top: 20px;
	text-align: center;

	${StyledButton} {
		height: 48px;
		width: 100%;
		justify-content: center;
	}
`;

export const StyledAuthAction = styled.div<{ alignRight?: boolean }>`
	text-align: ${props => (props?.alignRight ? 'right' : 'center')};
	padding-top: 10px;
`;

export const StyledAuthNote = styled.div`
	margin-top: 24px;
	font-size: 14px;
	font-weight: 600;
	color: ${props => colorToString(props.theme.variant.default.color.text.subline)};
	text-align: center;
`;

export const StyledAuthLink = styled(Link)`
	background: none;
	font: inherit;
	border: none;
	color: ${props => colorToString(props.theme.variant.default.color.label.interactive.primary)};
	cursor: pointer;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
`;

export const StyledAuthCheckboxes = styled.div`
	padding-top: 24px;
`;

export const StyledAuthCheckbox = styled.div`
	& + & {
		margin-top: 24px;
	}

	${StyledCheckboxIcon} {
		width: 18px;
		height: 18px;
		border-width: 1px;
	}

	${StyledCheckboxText} {
		font-size: 16px;
		font-weight: 400;
		text-align: left;
	}
`;

export const StyledAuthHeadIcon = styled.div`
	margin-bottom: 20px;
`;

export const StyledAuthWrapperSuccess = styled.div`
	text-align: center;

	${StyledAuthTitle} {
		margin-bottom: 10px;
		font: 600 24px/1.25 'Source Sans Pro', sans-serif;
		text-align: inherit;
	}
`;
