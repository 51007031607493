import styled from 'styled-components';
import { colorToString } from '@dto/themes/types/color';
import { spacingToString } from '@dto/themes/types';

export const StyledAccordions = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${props => spacingToString(props.theme.variant.default.spacing.s5.default)};
`;

export const StyledAccordion = styled.div`
	overflow: hidden;
	position: relative;
	width: 100%;
	border-radius: 8px;
	background-color: ${props => colorToString(props.theme.variant.default.color.background.standard)};
	color: ${props => colorToString(props.theme.variant.default.color.text.copy)}
	box-shadow: 0 0 2px rgba(39, 28, 26, 0.0373142), 0 4px 8px rgba(39, 28, 26, 0.159719);
`;

export const StyledAccordionHead = styled.button<{ isActive: boolean }>`
	display: flex;
	gap: ${props => spacingToString(props.theme.variant.default.spacing.s4.default)};
	justify-content: space-between;
	width: 100%;
	border: none;
	border-radius: 0;
	padding: ${props => spacingToString(props.theme.variant.default.spacing.s4.default)};
	background: transparent;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	cursor: pointer;
	appearance: none;

	svg {
		transform: ${props => (props.isActive ? 'rotate(180deg)' : 'rotate(0)')};
		transition: transform 0.3s;
	}
`;

export const StyledAccordionBody = styled.div<{ height: number; ref: any }>`
	overflow: hidden;
	height: ${props => props.height}px;
	transition: height 0.3s;
`;
export const StyledAccordionBodyInner = styled.div`
	padding: ${props => spacingToString(props.theme.variant.default.spacing.s2.default)}
		${props => spacingToString(props.theme.variant.default.spacing.s4.default)}
		${props => spacingToString(props.theme.variant.default.spacing.s4.default)};
`;
