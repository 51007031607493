import useGetPrivacy from '@src/api/hooks/legal/useGetPrivacy';
import Accordion from '@ui/Accordion/Accordion';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import BaseButtonBack from '@ui/BaseBackButton/BaseButtonBack';
import { useAdobeAnalytics } from '@src/contexts/AdobeAnalytics/AdobeAnalyticsContext';
import { useUsercentricsEffectOnce } from '@src/hooks/useUsercentricsEffectOnce';
import { getTenant } from '@src/utilities/getTenant';

import {
	StyledPageAccordions,
	StyledPageContent,
	StyledPageIntroduction,
	StyledPageMain,
	StyledPageSectionContent,
	StyledPageTitle,
} from '@ui/StyledLegal/styles';

export const PrivacyPolicy = () => {
	const { privacyData, isLoading } = useGetPrivacy({ shouldInvalidate: true });
	const { dataLayer } = useAdobeAnalytics();

	useUsercentricsEffectOnce(() => dataLayer.pageView('legal:privacy-policy'));

	if (isLoading) {
		return <FullScreenLoader />;
	}

	return (
		<StyledPageContent>
			<BaseButtonBack />

			{!!privacyData && (
				<>
					<StyledPageTitle>{privacyData.header}</StyledPageTitle>
					<StyledPageIntroduction
						dangerouslySetInnerHTML={{ __html: privacyData.introduction }}
					></StyledPageIntroduction>

					<StyledPageMain>
						<StyledPageAccordions>
							{privacyData?.section?.map(section => (
								<Accordion key={section.section_headline} title={section.section_headline} isExpanded={false}>
									<StyledPageSectionContent
										tenant={getTenant()}
										dangerouslySetInnerHTML={{ __html: section.section_content }}
									/>
								</Accordion>
							))}
						</StyledPageAccordions>
					</StyledPageMain>
				</>
			)}
		</StyledPageContent>
	);
};
