import { animated, useTransition } from 'react-spring';

import { IToast } from '@ui/Toast/types';
import Toast from '@ui/Toast/Toast';

interface IToastGroupItemsProps {
	items: IToast[];
}

const ToastGroupItems = ({ items }: IToastGroupItemsProps) => {
	const transitions = useTransition(items, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
	});

	return (
		<div>
			{transitions((style, toast) => (
				<animated.div key={toast.id} style={style}>
					<Toast {...toast} />
				</animated.div>
			))}
		</div>
	);
};

export default ToastGroupItems;
