import { PropsWithChildren, ReactNode } from 'react';

import BrandLogos from '@components/BrandLogos/BrandLogos';

import { ReactComponent as TenantLogo } from '@assets/images/dertour/logo.svg';
import { StyledAuth, StyledAuthContent, StyledAuthHead, StyledAuthImageHolder } from '@pages/Auth/styles';

interface AuthLayout {
	image?: ReactNode;
	header?: ReactNode;
	showBrandLogos?: boolean;
}

export const AuthLayout = ({ showBrandLogos = true, image, header, children }: PropsWithChildren<AuthLayout>) => {
	return (
		<StyledAuth>
			<StyledAuthContent>
				<StyledAuthHead>
					{image ?? (
						<StyledAuthImageHolder>
							<TenantLogo />
						</StyledAuthImageHolder>
					)}

					{showBrandLogos && <BrandLogos />}

					{header}
				</StyledAuthHead>

				{children}
			</StyledAuthContent>
		</StyledAuth>
	);
};
