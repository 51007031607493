declare global {
	interface Window {
		grecaptcha: any;
		verifiedCallback: any;
		Cypress?: any;
	}
}

export enum IReCaptchaActionType {
	verifyEmail = 'email_verify',
	login = 'login',
	register = 'register',
	contact = 'contact',
	changePassword = 'change_password',
}
