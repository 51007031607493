import styled from 'styled-components';
import { colorToString } from '@dto/themes/types/color';
import { device } from '@src/utilities/media';

export const StyledModalWrapper = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	padding: 42px 72px;
	z-index: 15;
	overflow-y: auto;
	background: rgba(0, 0, 0, 0.55);

	@media ${device.mobile} {
		padding: 42px 24px;
	}
`;

export const StyledModalDialog = styled.div<{ modalWidth?: string }>`
	position: relative;
	z-index: 10;
	background: ${props => colorToString(props.theme.variant.default.color.background.standard)};
	width: 100%;
	max-width: ${props => (props.modalWidth ? props.modalWidth : '490px')};
	margin: auto;
	border-radius: 8px;
	overflow: hidden;

	@media ${device.mobile} {
		max-width: 100%;
	}
`;

export const StyledModalHead = styled.div`
	position: relative;
	padding: 24px 32px;
	border-bottom: 1px solid ${props => colorToString(props.theme.variant.default.color.border.separateLine)};

	@media ${device.mobile} {
		padding: 15px 24px;
	}

	h2 {
		color: ${props => colorToString(props.theme.variant.default.color.text.headline)};
		font-weight: 500;
		padding-right: 30px;
	}
`;

export const StyledModalContent = styled.div`
	position: relative;
	padding: 32px 32px 24px;

	@media ${device.mobile} {
		overflow: auto;
		padding: 24px;
	}
`;

export const StyledModalButtonClose = styled.button`
	appearance: none;
	border: none;
	border-radius: 0;
	background: transparent;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: absolute;
	top: 20px;
	right: 8px;
	z-index: 10;
	transition: color 0.3s;

	path {
		fill: currentColor;
	}

	&:hover {
		color: ${props => colorToString(props.theme.variant.default.color.text.decoration)};
	}
`;

export const StyledModalEntry = styled.div`
	line-height: 1.5;
	color: ${props => colorToString(props.theme.variant.default.color.text.description)};
	padding-bottom: 30px;

	p ~ p {
		margin-top: 20px;
	}
`;

export const StyledModalActions = styled.div`
	margin: 0 -32px;
	width: calc(100% + 64px);
	padding: 24px 32px 0;
	display: flex;
	justify-content: space-between;
	border-top: 1px solid ${props => colorToString(props.theme.variant.default.color.border.separateLine)};

	button:last-child {
		margin-left: auto;
	}
`;

export const StyledModalBody = styled.div`
	margin-bottom: 20px;
	overflow-y: auto;
`;
