import { NotificationType } from '@pages/Notification/types';
import { NotificationServiceStorage } from '@src/utilities/support/LocalStorageAdapter';

export const useNotificationStorage = () => {
	const setNotificationStorage = (notification: NotificationType) => {
		NotificationServiceStorage.setItem(
			NotificationServiceStorage.keys.notification,
			btoa(
				JSON.stringify({
					component: notification,
				}),
			),
		);
	};

	const getNotificationStorage = () => {
		const notification = NotificationServiceStorage.getItem(NotificationServiceStorage.keys.notification);

		return notification ? JSON.parse(atob(notification)) : null;
	};

	const removeNotificationStorage = () =>
		NotificationServiceStorage.removeItem(NotificationServiceStorage.keys.notification);

	return {
		getNotificationStorage,
		setNotificationStorage,
		removeNotificationStorage,
	};
};
