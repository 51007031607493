import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ResetPasswordFormValidationSchema } from '@pages/Auth/ResetPassword/resetPassword.schema';
import { IResetPasswordFormData } from '@src/types/auth';
import { IEmailProtectedRouteState } from '@src/router/guards/EmailProtectedGuard/types';
import { emailMask } from '@src/utilities/helpers';
import { useResetPassword } from '@src/api/hooks/auth/useResetPassword';
import { useValidatedForm } from '@src/hooks/useValidatedForm';
import { useNotificationNavigate } from '@pages/Notification/useNotification';
import { useAdobeAnalytics } from '@src/contexts/AdobeAnalytics/AdobeAnalyticsContext';
import { useUsercentricsEffectOnce } from '@src/hooks/useUsercentricsEffectOnce';
import ValidatedFormInput from '@components/ValidatedFormInput/ValidatedFormInput';
import BaseButton from '@ui/BaseButton/BaseButton';
import { AuthLayout } from '@pages/Auth/AuthLayout/AuthLayout';

import { StyledAuthAction, StyledAuthActions, StyledAuthEntry, StyledAuthItem } from '@pages/Auth/styles';

export const ResetPassword = () => {
	const location = useLocation();
	const { t } = useTranslation();
	const { isLoading, resetPassword } = useResetPassword();
	const { notificationNavigate } = useNotificationNavigate();
	const { dataLayer } = useAdobeAnalytics();

	useUsercentricsEffectOnce(() => dataLayer.pageView('password:reset'));

	const state = location.state as IEmailProtectedRouteState;
	const { ValidatedFormProvider } = useValidatedForm<IResetPasswordFormData>({
		schema: ResetPasswordFormValidationSchema,
		options: {
			defaultValues: {
				code: '',
				password: '',
				repeatPassword: '',
			},
		},
		handleFormSubmit: async data => {
			await resetPassword(
				{
					code: data.code,
					password: data.password,
					email: state.email!,
				},
				{
					onSuccess: () => {
						dataLayer.event('resetPassword');

						notificationNavigate('PasswordSuccessfullyChanged');
					},
				},
			);
		},
	});

	return (
		<AuthLayout
			header={<StyledAuthEntry>{t('auth.resetPassword.entry', { email: emailMask(state.email!) })}</StyledAuthEntry>}
		>
			<ValidatedFormProvider autocomplete="off">
				<StyledAuthItem>
					<ValidatedFormInput localeGroup="auth.resetPassword" name="code" icon="shield" autocomplete="none" />
				</StyledAuthItem>

				<StyledAuthItem>
					<ValidatedFormInput
						localeGroup="auth.resetPassword"
						name="password"
						icon="lock"
						type="password"
						autocomplete="none"
					/>
				</StyledAuthItem>

				<StyledAuthItem>
					<ValidatedFormInput
						localeGroup="auth.resetPassword"
						name="repeatPassword"
						icon="lock"
						type="password"
						autocomplete="none"
					/>
				</StyledAuthItem>

				<StyledAuthActions>
					<StyledAuthAction>
						<BaseButton isLoading={isLoading} type="submit">
							{t('auth.changePassword')}
						</BaseButton>
					</StyledAuthAction>
				</StyledAuthActions>
			</ValidatedFormProvider>
		</AuthLayout>
	);
};
