import { Suspense } from 'react';

import { useNotification } from '@pages/Notification/useNotification';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import { BaseErrorDisplay } from '@pages/Notification/components/BaseErrorDisplay';

export const NotificationPage = () => {
	const { isAccessible, NotificationComp } = useNotification();

	if (!isAccessible) {
		return <BaseErrorDisplay fieldName="notFound" />;
	}

	return (
		<Suspense fallback={<FullScreenLoader />}>
			<NotificationComp />
		</Suspense>
	);
};
