import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import BaseInput from '@ui/BaseInput/BaseInput';
import { IconValue } from '@dto/themes/theme/icon';
import { useEffect } from 'react';

interface IValidatedFormInput {
	localeGroup: string;
	name: string;
	type?: 'text' | 'password' | 'email';
	icon?: IconValue;
	disabled?: boolean;
	autoFocus?: boolean;
	autocomplete?: 'on' | 'off' | 'none' | 'false';
	isTextarea?: boolean;
}

const ValidatedFormInput = (props: IValidatedFormInput) => {
	const { name, localeGroup, type, icon, disabled, autoFocus, autocomplete, isTextarea } = props;
	const { t } = useTranslation();
	const { control, setFocus } = useFormContext();
	const { field, fieldState } = useController({ name, control });
	const inputLabel = t(`${localeGroup}.${name}.label`);
	const inputPlaceholder = t(`${localeGroup}.${name}.placeholder`);
	const inputError = t(`${localeGroup}.${name}.error.${fieldState.error?.type}`);

	useEffect(() => {
		if (autoFocus) {
			setFocus(name);
		}
	}, [autoFocus, setFocus]);

	return (
		<BaseInput
			id={name}
			label={inputLabel}
			placeholder={inputPlaceholder}
			type={type ?? 'text'}
			aria-label={name}
			errorText={fieldState.error ? inputError : ''}
			icon={icon}
			autocomplete={autocomplete}
			isTextarea={isTextarea}
			{...field}
			disabled={disabled}
		/>
	);
};

export default ValidatedFormInput;
