import { Fragment } from 'react';
import { useTheme } from 'styled-components';

import { AuthLayout } from '@pages/Auth/AuthLayout/AuthLayout';
import BaseButton from '@ui/BaseButton/BaseButton';

import { ReactComponent as IconDashboard } from '@assets/svg/icon-dashboard.svg';
import { StyledAuthAction, StyledAuthActions, StyledAuthEntry, StyledAuthTitle } from '@pages/Auth/styles';
import { useEnv } from '@src/hooks/useEnv';
import { useTranslation } from 'react-i18next';
import { getTenantCurrentConfig } from '@src/config/tenantConfig';
import { isReiseplanerTenant } from '@src/utilities/getTenant';

const tenantConfig = getTenantCurrentConfig();

export const Platforms = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const env = useEnv();

	const { linkOut: IconLinkOut } = theme.icon;

	return (
		<AuthLayout
			header={
				<Fragment>
					<StyledAuthTitle textAlign="center">{t('platforms.title')}</StyledAuthTitle>

					<StyledAuthEntry>{t('platforms.description')}</StyledAuthEntry>
				</Fragment>
			}
		>
			<StyledAuthActions>
				{!isReiseplanerTenant && (
					<StyledAuthAction>
						<BaseButton href={tenantConfig.homeUrl} variant="secondary" {...tenantConfig.homeButtonTracing}>
							<IconLinkOut width={20} height={20} />
							{tenantConfig.home}
						</BaseButton>
					</StyledAuthAction>
				)}

				<StyledAuthAction>
					<BaseButton
						href={env.base.appEndpoint}
						variant="secondary"
						data-linktype="navigation"
						data-linkid="helper_link_cs"
					>
						<IconDashboard />
						DERTOUR Reiseplaner
					</BaseButton>
				</StyledAuthAction>
			</StyledAuthActions>
		</AuthLayout>
	);
};
