import useGetTermsOfUse from '@src/api/hooks/legal/useGetTermsOfUse';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import BaseButtonBack from '@ui/BaseBackButton/BaseButtonBack';
import { useAdobeAnalytics } from '@src/contexts/AdobeAnalytics/AdobeAnalyticsContext';
import { useUsercentricsEffectOnce } from '@src/hooks/useUsercentricsEffectOnce';

import {
	StyledPageContent,
	StyledPageMain,
	StyledPageSection,
	StyledPageSectionContent,
	StyledPageTitle,
} from '@ui/StyledLegal/styles';

export const TermsOfUse = () => {
	const { termsOfUseData, isLoading } = useGetTermsOfUse({ shouldInvalidate: true });
	const { dataLayer } = useAdobeAnalytics();

	useUsercentricsEffectOnce(() => dataLayer.pageView('legal:terms-of-use'));

	if (isLoading) {
		return <FullScreenLoader />;
	}

	return (
		<StyledPageContent>
			<BaseButtonBack />

			{!!termsOfUseData && (
				<>
					<StyledPageTitle>{termsOfUseData.title}</StyledPageTitle>

					<StyledPageMain>
						{!!termsOfUseData?.rich_text_editor && (
							<StyledPageSection>
								<StyledPageSectionContent dangerouslySetInnerHTML={{ __html: termsOfUseData.rich_text_editor }} />
							</StyledPageSection>
						)}
					</StyledPageMain>
				</>
			)}
		</StyledPageContent>
	);
};
