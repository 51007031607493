import { useMutation } from '@tanstack/react-query';

import { post } from '@src/api/internalApi';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import { IAuthVerifyAxiosResponse, IAuthVerifyFormData } from '@src/types/auth';

export const useVerification = () => {
	const { api } = useRoutingContext();

	const {
		isLoading,
		data,
		error,
		mutateAsync: verifyCustomer,
	} = useMutation(['auth/verification/customer'], (data: Omit<IAuthVerifyFormData, 'isValidRecaptcha'>) =>
		post<IAuthVerifyAxiosResponse>(api.authVerification(), data),
	);

	return {
		isLoading,
		data,
		error,
		verifyCustomer,
	};
};
