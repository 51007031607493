import styled from 'styled-components';

export const StyledFullScreenLoader = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	height: 100vh;
	justify-content: center;
	align-items: center;
	background: #fff;
`;
