import { PropsWithChildren, useMemo } from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isAxiosError } from 'axios';
import i18next from 'i18next';

import { useToastContext } from '@ui/Toast/ToastContext';
import { ShowToastFn } from '@ui/Toast/types';
import { BaseError } from '@src/api/errors';
import { ConfigService } from '@src/config/ConfigService';

const handleToast = (showToast: ShowToastFn, errorMessage?: string | null) => {
	showToast({
		title: i18next.t('errors.EncounteredAnError'),
		type: 'error',
		content: errorMessage?.length ? errorMessage : i18next.t('errors.UnexpectedError'),
		duration: 5 * 1000, // 5 seconds
	});
};

const handleError = (error: unknown, showToast: ShowToastFn) => {
	const axiosError = error as BaseError;

	const errorMessage = isAxiosError<BaseError>(error)
		? axiosError.response?.data?.message ?? i18next.t('errors.UnexpectedError')
		: i18next.t('errors.UnexpectedError');

	handleToast(showToast, errorMessage);
};

const queryClient = (showToast: ShowToastFn) =>
	new QueryClient({
		mutationCache: new MutationCache({
			onError: error => {
				handleError(error, showToast);
			},
		}),
		queryCache: new QueryCache({
			onError: (error, query) => {
				if (query.state.data !== undefined) {
					handleError(error, showToast);
				}
			},
		}),
		defaultOptions: {
			queries: {
				retry: false,
				refetchOnWindowFocus: false,
				staleTime: 1000 * 60 * 5, // 5 minutes
			},
		},
	});

const ClientProvider = ({ children }: PropsWithChildren) => {
	const { showToast } = useToastContext();

	const cachedQueryClient = useMemo(() => queryClient(showToast), []);

	return (
		<QueryClientProvider client={cachedQueryClient}>
			{children}

			{ConfigService.isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
		</QueryClientProvider>
	);
};

export default ClientProvider;
