import Accordion from '@ui/Accordion/Accordion';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import BaseButtonBack from '@ui/BaseBackButton/BaseButtonBack';
import useGetFaqs from '@src/api/hooks/legal/useGetFaqs';
import { useUsercentricsEffectOnce } from '@src/hooks/useUsercentricsEffectOnce';
import { useAdobeAnalytics } from '@src/contexts/AdobeAnalytics/AdobeAnalyticsContext';

import {
	StyledPageAccordions,
	StyledPageContent,
	StyledPageIntroduction,
	StyledPageMain,
	StyledPageSection,
	StyledPageSectionContent,
	StyledPageSectionContentEntry,
	StyledPageSectionTitle,
	StyledPageTitle,
} from '@ui/StyledLegal/styles';

export const Faqs = () => {
	const { faqsData, isLoading } = useGetFaqs();
	const { dataLayer } = useAdobeAnalytics();

	useUsercentricsEffectOnce(() => dataLayer.pageView('faqs'));

	if (isLoading) {
		return <FullScreenLoader />;
	}

	return (
		<StyledPageContent>
			<BaseButtonBack />

			{!!faqsData && (
				<>
					<StyledPageTitle>{faqsData.header}</StyledPageTitle>
					<StyledPageIntroduction dangerouslySetInnerHTML={{ __html: faqsData.info_text }} />

					<StyledPageMain>
						{faqsData?.sections?.map(section => (
							<StyledPageSection key={section.header}>
								<StyledPageSectionTitle>{section.header}</StyledPageSectionTitle>
								<StyledPageSectionContent>
									<StyledPageAccordions>
										{section.questions.map(qst => (
											<Accordion key={qst.question} title={qst.question} isExpanded={false}>
												<StyledPageSectionContentEntry dangerouslySetInnerHTML={{ __html: qst.answer }} />
											</Accordion>
										))}
									</StyledPageAccordions>
								</StyledPageSectionContent>
							</StyledPageSection>
						))}
					</StyledPageMain>
				</>
			)}
		</StyledPageContent>
	);
};
