import styled from 'styled-components';

import { ToastPositionsType } from '@ui/Toast/types';

const getStylesByPosition = (position: ToastPositionsType) => {
	const positionStylesMap = {
		top: {
			left: '50%',
			top: '0',
			transform: 'translateX(-50%)',
		},
		'top-left': {
			left: '0',
			top: '0',
		},
		'top-right': {
			right: '0',
			top: '0',
		},
		bottom: {
			left: '50%',
			bottom: '0',
			transform: 'translateX(-50%)',
		},
		'bottom-left': {
			left: '0',
			bottom: '0',
		},
		'bottom-right': {
			right: '0',
			bottom: '0',
		},
	};

	return positionStylesMap[position];
};

export const StyledToastGroup = styled.div<{ position: ToastPositionsType }>`
	position: fixed;
	z-index: 99999;
	display: flex;
	width: 100%;
	max-width: 400px;
	flex-direction: column;

	${props => getStylesByPosition(props.position)};
`;

export const StyledToast = styled.div`
	margin: 10px;
`;
