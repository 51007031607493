import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { colorToString } from '@dto/themes/types/color';

import StyledShell from '@ui/StyledShell/StyledShell';
import { device } from '@src/utilities/media';

export const StyledFooter = styled.footer`
	display: flex;
	width: 100%;
	height: 93px;
	padding: 10px 0;
	border-top: 1px solid ${props => colorToString(props.theme.variant.default.color.border.separateLine)};
	background: ${props => colorToString(props.theme.variant.default.color.background.standard)};

	${StyledShell} {
		align-items: center;
		justify-content: space-between;
	}

	ul {
		list-style: none;
		display: flex;
		gap: 32px;
	}

	@media ${device.mobile} {
		height: auto;
		padding: 16px 0;

		ul {
			flex-wrap: wrap;
			gap: 24px;
			padding-right: 10px;
		}
	}
`;

const StyledFooterNavLinkCSS = css`
	font-size: 14px;
	color: ${props => colorToString(props.theme.variant.default.color.text.copy)};
	text-decoration: none;
	transition: color 0.3s;

	&:hover {
		color: ${props => colorToString(props.theme.variant.default.color.border.critical)};
	}
`;

export const StyledFooterNavLink = styled(NavLink)`
	${StyledFooterNavLinkCSS}
`;

export const StyledFooterNavButton = styled.button`
	${StyledFooterNavLinkCSS};
	background: none;
	border: 0 none;
	cursor: pointer;

	&:disabled {
		opacity: 0.5;
		cursor: default;
	}
`;
