import { colorToString } from '@dto/themes/types';
import styled from 'styled-components';
import { device } from '@src/utilities/media';

export const StyledAuthMediaSlider = styled.div`
	padding-top: 5px;
	flex-grow: 1;
	overflow: hidden;
	border-radius: 0 0 0 8px;

	.swiper {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.swiper-pagination {
		position: static;
		order: -1;
		display: flex;
		justify-content: flex-start;
		margin-bottom: 25px;

		@media ${device.tablet} {
			justify-content: center;
			padding-right: 35px;
		}

		@media ${device.mobile} {
			padding-right: 0;
		}
	}

	.swiper-pagination-bullet {
		background: ${props => colorToString(props.theme.variant.default.color.border.disabled)};
		opacity: 0.5;
	}

	.swiper-pagination-bullet-active {
		opacity: 1;
		background: ${props => colorToString(props.theme.variant.default.color.icon.interactive.primary)};
	}

	.swiper-slide {
		opacity: 0 !important;
	}

	.swiper-slide-active {
		opacity: 1 !important;
	}
`;
