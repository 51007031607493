import styled, { css } from 'styled-components';
import { colorToString, spacingToString } from '@dto/themes/types';

import { StyledAccordion, StyledAccordionBody, StyledAccordionHead } from '@ui/Accordion/styles';
import { StyledButtonBack } from '@ui/BaseBackButton/styles';
import { device } from '@src/utilities/media';

export const StyledPageContent = styled.div`
	width: 100%;
	padding: ${props => spacingToString(props.theme.variant.default.spacing.s10.default)} 0;
	margin-bottom: 20px;

	${StyledButtonBack} {
		margin-bottom: 32px;
	}

	@media ${device.tablet} {
		padding: ${props => spacingToString(props.theme.variant.default.spacing.s7.default)} 0;
	}

	@media ${device.mobile} {
		padding: ${props => spacingToString(props.theme.variant.default.spacing.s5.default)} 0;
	}
`;

export const StyledPageTitle = styled.h2`
	margin-bottom: ${props => spacingToString(props.theme.variant.default.spacing.s7.default)};
	font-size: 40px;
	line-height: 1.25;
	font-weight: 600;
	color: ${props => colorToString(props.theme.variant.default.color.text.headline)};
	letter-spacing: -0.2px;

	@media ${device.tablet} {
		font-size: 32px;
		margin-bottom: 20px;
	}

	@media ${device.mobile} {
		font-size: 24px;
	}
`;

export const StyledPageIntroduction = styled.div`
	margin-bottom: ${props => spacingToString(props.theme.variant.default.spacing.s7.default)};
`;

export const StyledPageSectionTitle = styled.h3`
	margin-bottom: ${props => spacingToString(props.theme.variant.default.spacing.s4.default)};
	font-size: 28px;
	line-height: 1.25;
	font-weight: 600;
	color: ${props => colorToString(props.theme.variant.default.color.text.headline)};
	letter-spacing: -0.2px;
	margin-bottom: 1rem;

	@media ${device.mobile} {
		font-size: 20px;
	}
`;

export const StyledPageSectionContent = styled.div<{ tenant?: string }>`
	p:empty {
		display: none;
	}

	[id^='opt_out_'] {
		display: none;
	}

	${props => {
		return (
			props?.tenant &&
			css`
				[id^='opt_out_${props.tenant}'] {
					display: block;
				}
			`
		);
	}}
`;

export const StyledPageMain = styled.div`
	color: ${props => colorToString(props.theme.variant.default.color.text.copy)};
	line-height: 1.5;

	section {
		margin-bottom: ${props => spacingToString(props.theme.variant.default.spacing.s8.default)};

		&:first-of-type {
			p:not(:last-child) {
				margin-bottom: 0;
			}
		}
	}

	p:not(:last-child) {
		margin-bottom: ${props => spacingToString(props.theme.variant.default.spacing.s7.default)};
	}

	a {
		color: ${props => colorToString(props.theme.variant.default.color.text.copy)};
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	address {
		font-style: normal;
	}

	h3:not([class]) {
		font-size: 28px;
		line-height: 1.5;
		font-weight: 600;
		margin-bottom: ${props => spacingToString(props.theme.variant.default.spacing.s2.default)};
		color: ${props => colorToString(props.theme.variant.default.color.text.headline)};
		letter-spacing: -0.2px;
	}

	ul {
		list-style: none;

		&:not(:last-child) {
			margin-bottom: ${props => spacingToString(props.theme.variant.default.spacing.s7.default)};
		}

		li {
			position: relative;
			padding-left: ${props => spacingToString(props.theme.variant.default.spacing.s6.default)};

			&:not(:last-child) {
				margin-bottom: ${props => spacingToString(props.theme.variant.default.spacing.s2.default)};
			}

			&:before {
				content: '';
				position: absolute;
				top: ${props => spacingToString(props.theme.variant.default.spacing.s2.default)};
				left: ${props => spacingToString(props.theme.variant.default.spacing.s2.default)};
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: ${props => colorToString(props.theme.variant.default.color.icon.standard)};
			}
		}
	}
`;

export const StyledPageAccordions = styled.div`
	counter-reset: accordion-title;
	border-top: 1px solid #d4d1d1;

	${StyledAccordion} {
		background-color: transparent;
		border-bottom: 1px solid #d4d1d1;
		border-radius: 0;
		box-shadow: none;
		counter-increment: accordion-title;
	}

	${StyledAccordionHead} {
		gap: ${props => spacingToString(props.theme.variant.default.spacing.s1.default)};
		padding: ${props => spacingToString(props.theme.variant.default.spacing.s4.default)} 0;
		color: ${props => colorToString(props.theme.variant.default.color.text.headline)};
		font-size: 24px;
		line-height: 1.34;
		justify-content: flex-start;
		text-align: left;

		&:before {
			content: counter(accordion-title) '. ';
		}

		span {
			margin-left: auto;
		}

		@media ${device.tablet} {
			font-size: 20px;
		}

		@media ${device.mobile} {
			font-size: 18px;
		}
	}

	${StyledAccordionBody} {
		padding: 0;

		h3 {
			font-size: 18px;
		}

		h4 {
			font-size: 16px;
			line-height: 1.5;
			margin-bottom: ${props => spacingToString(props.theme.variant.default.spacing.s2.default)};
			color: ${props => colorToString(props.theme.variant.default.color.text.headline)};
		}
	}

	&:only-child {
		margin-top: 40px;

		@media ${device.mobile} {
			margin-top: 20px;
		}
	}
`;

export const StyledPageSection = styled.section``;

export const StyledLegalConsentContent = styled.div`
	&:not(:last-child) {
		margin-bottom: 20px;
	}

	h3 {
		margin-bottom: 1rem;
	}

	a {
		color: ${props => colorToString(props.theme.variant.default.color.text.copy)};
		text-decoration: underline;
		word-break: break-all;
		font-weight: 600;

		&:hover {
			&,
			u {
				text-decoration: none;
			}
		}
	}

	p ~ p,
	p ~ ul,
	p ~ h3,
	p ~ h4 {
		margin-top: 1rem;
	}

	ul {
		list-style: none;

		&:not(:last-child) {
			margin-bottom: ${props => spacingToString(props.theme.variant.default.spacing.s2.default)};
		}

		li {
			position: relative;
			padding-left: ${props => spacingToString(props.theme.variant.default.spacing.s3.default)};

			&:not(:last-child) {
				margin-bottom: ${props => spacingToString(props.theme.variant.default.spacing.s1.default)};
			}

			&:before {
				content: '';
				position: absolute;
				top: ${props => spacingToString(props.theme.variant.default.spacing.s2.default)};
				left: ${props => spacingToString(props.theme.variant.default.spacing.s1.default)};
				display: inline-block;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background-color: ${props => colorToString(props.theme.variant.default.color.icon.standard)};
			}
		}
	}
`;

export const StyledPageSectionContentEntry = styled.div`
	h3 {
		margin-bottom: 0;
	}

	&:not(:last-child) {
		margin-bottom: 40px;
	}

	p:not(:last-child) {
		margin-bottom: 0;
	}
`;
