import useGetImprint from '@src/api/hooks/legal/useGetImprint';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import BaseButtonBack from '@ui/BaseBackButton/BaseButtonBack';
import { useAdobeAnalytics } from '@src/contexts/AdobeAnalytics/AdobeAnalyticsContext';
import { useUsercentricsEffectOnce } from '@src/hooks/useUsercentricsEffectOnce';

import {
	StyledPageContent,
	StyledPageMain,
	StyledPageSection,
	StyledPageSectionContent,
	StyledPageSectionTitle,
	StyledPageTitle,
} from '@ui/StyledLegal/styles';

export const Imprint = () => {
	const { imprintData, isLoading } = useGetImprint();
	const { dataLayer } = useAdobeAnalytics();

	useUsercentricsEffectOnce(() => dataLayer.pageView('legal:notice'));

	if (isLoading) {
		return <FullScreenLoader />;
	}

	return (
		<StyledPageContent>
			<BaseButtonBack />

			{!!imprintData && (
				<>
					<StyledPageTitle>{imprintData.title}</StyledPageTitle>

					<StyledPageMain>
						<StyledPageSectionTitle>{imprintData.company_name}</StyledPageSectionTitle>

						{!!imprintData.section &&
							imprintData.section.map(section => (
								<StyledPageSection key={section.section_headline}>
									<StyledPageSectionTitle>{section.section_headline}</StyledPageSectionTitle>

									<StyledPageSectionContent dangerouslySetInnerHTML={{ __html: section.section_content as string }} />
								</StyledPageSection>
							))}
					</StyledPageMain>
				</>
			)}
		</StyledPageContent>
	);
};
