import { Outlet } from 'react-router-dom';

import BaseFooter from '@components/BaseFooter/BaseFooter';

import { StyledMain, StyledWrapper, StyledWrapperInner } from '@components/BaseLayout/styles';
import StyledShell from '@ui/StyledShell/StyledShell';

interface IBaseLayoutProps {
	withContainer?: boolean;
	withFooter?: boolean;
}

export const BaseLayout = ({ withContainer = false, withFooter = true }: IBaseLayoutProps) => (
	<StyledWrapper>
		<StyledWrapperInner>
			<StyledMain>
				{withContainer ? (
					<StyledShell>
						<Outlet />
					</StyledShell>
				) : (
					<Outlet />
				)}
			</StyledMain>

			{withFooter && <BaseFooter />}
		</StyledWrapperInner>
	</StyledWrapper>
);
