import Spinner from '@ui/Spinner/Spinner';
import { StyledFullScreenLoader } from '@components/FullScreenLoader/styles';

const FullScreenLoader = () => {
	return (
		<StyledFullScreenLoader>
			<Spinner width={50} height={50} />
		</StyledFullScreenLoader>
	);
};

export default FullScreenLoader;
