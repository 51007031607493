import styled from 'styled-components';
import { device } from '@src/utilities/media';

const StyledShell = styled.div`
	width: 100%;
	max-width: 1280px;
	padding: 0 40px;
	margin: 0 auto;
	display: flex;

	@media ${device.tablet} {
		padding: 0 32px;
	}

	@media ${device.mobile} {
		padding: 0 24px;
	}
`;

export default StyledShell;
