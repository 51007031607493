import { ReactNode } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IconValue } from '@dto/themes/theme/icon';
import BaseCheckbox, { BaseCheckboxProps } from '@ui/BaseCheckbox/BaseCheckbox';

import { StyledPageLink } from '@ui/Page/styles';

interface IValidatedFormCheckbox extends Omit<BaseCheckboxProps, 'id' | 'label'> {
	localeGroup?: string;
	name: string;
	icon?: IconValue;
	labelLinkHandler?: () => void;
	buttonProps?: Record<string, string>;
	label?: string | ReactNode;
}

const ValidatedFormCheckbox = (props: IValidatedFormCheckbox) => {
	const { name, localeGroup, labelLinkHandler, label, ...restProps } = props;
	const { t } = useTranslation();
	const { control } = useFormContext();
	const { field, fieldState } = useController({ name, control });
	const inputLabel = label ? label : t(`${localeGroup}.${name}.label.text`);
	const inputLabelLinkText = t(`${localeGroup}.${name}.label.linkText`);
	const inputError = t(`${localeGroup}.${name}.error.${fieldState.error?.type}`);

	return (
		<BaseCheckbox
			id={name}
			label={
				<>
					{inputLabel}{' '}
					{inputLabelLinkText !== `${localeGroup}.${name}.label.linkText` && (
						<StyledPageLink type="button" onClick={labelLinkHandler} {...(props?.buttonProps ?? {})}>
							{inputLabelLinkText}
						</StyledPageLink>
					)}
				</>
			}
			checked={field.value}
			onChange={field.onChange}
			errorText={fieldState.error ? inputError : ''}
			{...restProps}
		/>
	);
};

export default ValidatedFormCheckbox;
