import { colorToString } from '@dto/themes/types';
import styled from 'styled-components';
import { device } from '@src/utilities/media';

export const StyledAuthSliderItem = styled.div`
	display: flex;
	flex-direction: column;
`;

export const StyledAuthSliderItemHead = styled.div`
	font-size: 14px;
	padding-right: 35px;
	margin-bottom: 16px;

	@media ${device.tablet} {
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-bottom: 25px;
	}

	@media ${device.mobile} {
		padding-right: 0;
		margin-bottom: 0;
	}
`;

export const StyledAuthSliderItemEntry = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 6px;
	font-size: 14px;
	font-weight: 600;
	color: ${props => colorToString(props.theme.variant.default.color.text.description)};
`;

export const StyledAuthSliderItemImage = styled.div`
	margin-top: auto;
	position: relative;

	img {
		border-radius: 8px 0 0 8px;
		overflow: hidden;
		width: 100%;
		height: auto;

		@media ${device.tablet} {
			display: none;
		}
	}
`;
