import { Outlet } from 'react-router-dom';

import { useTenantTranslation } from '@src/hooks/useTenantTranslation';
import { AuthMediaSlider } from '@components/AuthMediaSlider/AuthMediaSlider';
import MediaRibbon from '@components/MediaRibbon/MediaRibbon';
import { AuthMediaNotice } from '@components/AuthMediaNotice/AuthMediaNotice';

import {
	StyledAuthMediaLayout,
	StyledAuthMediaLayoutContent,
	StyledAuthMediaLayoutInner,
	StyledAuthMediaLayoutMedia,
	StyledAuthMediaLayoutMediaBar,
} from '@components/AuthMediaLayout/styles';

export const AuthMediaLayout = () => {
	const { t } = useTenantTranslation();
	return (
		<StyledAuthMediaLayout>
			<StyledAuthMediaLayoutInner>
				<StyledAuthMediaLayoutContent>
					<Outlet />
				</StyledAuthMediaLayoutContent>

				<StyledAuthMediaLayoutMedia>
					<StyledAuthMediaLayoutMediaBar>
						<MediaRibbon title={t('auth.media.ribbon.title')} text={t('auth.media.ribbon.text')} />
					</StyledAuthMediaLayoutMediaBar>

					<AuthMediaSlider />
				</StyledAuthMediaLayoutMedia>

				<AuthMediaNotice />
			</StyledAuthMediaLayoutInner>
		</StyledAuthMediaLayout>
	);
};
