import { useMutation } from '@tanstack/react-query';

import { post } from '@src/api/internalApi';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import { IResetPasswordAxiosRequest } from '@src/types/auth';

export const useResetPassword = () => {
	const { api } = useRoutingContext();

	const {
		isLoading,
		data,
		error,
		mutateAsync: resetPassword,
	} = useMutation(['auth/reset-password'], (data: IResetPasswordAxiosRequest) => post<void>(api.resetPassword(), data));

	return {
		isLoading,
		data,
		error,
		resetPassword,
	};
};
