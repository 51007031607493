import { useMemo, useState } from 'react';

import useGetPrivacy from '@src/api/hooks/legal/useGetPrivacy';
import useGetTermsOfUse from '@src/api/hooks/legal/useGetTermsOfUse';
import useGetRightOfWithdrawal from '@src/api/hooks/legal/useGetRightOfWithdrawal';

export type DataModalType = 'dataPolicy' | 'termsOfUse' | 'rightOfWithdrawal';
export type DataVersionModalType = 'termsOfUseVersion' | 'dataPolicyVersion' | 'rightOfWithdrawalVersion';

export const useLegalConsentModal = () => {
	const { privacyData, isLoading: privacyPageIsLoading } = useGetPrivacy();
	const { termsOfUseData, isLoading: termsOfUsePageIsLoading } = useGetTermsOfUse();
	const { rightOfWithdrawalData, isLoading: rightOfWithdrawalLoading } = useGetRightOfWithdrawal();
	const [modalType, setModalType] = useState<DataModalType | undefined>();

	const dataMapper = {
		dataPolicy: privacyData!,
		termsOfUse: termsOfUseData!,
		rightOfWithdrawal: rightOfWithdrawalData!,
	};

	const formModalTypeMapper: Record<DataModalType, DataVersionModalType> = {
		termsOfUse: 'termsOfUseVersion',
		dataPolicy: 'dataPolicyVersion',
		rightOfWithdrawal: 'rightOfWithdrawalVersion',
	};

	const openModal = (type: DataModalType) => setModalType(type);

	const closeModal = () => setModalType(undefined);

	const modalData = useMemo(() => {
		return dataMapper[modalType!];
	}, [modalType]);

	const modalDataVersion = useMemo(() => {
		return formModalTypeMapper[modalType!];
	}, [modalType]);

	return {
		dataMapper,
		formModalTypeMapper,
		modalType,
		openModal,
		closeModal,
		modalData,
		modalDataVersion,
		isLoading: privacyPageIsLoading || termsOfUsePageIsLoading || rightOfWithdrawalLoading,
	};
};
