import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoCleverTours } from '@assets/images/clevertours/logo.svg';
import { ReactComponent as LogoDerTourInt } from '@assets/images/dertourinternational/logo.svg';
import { StyledAuthMediaLayoutBrands, StyledAuthMediaLayoutMediaNotice } from '@components/AuthMediaNotice/styles';

export const AuthMediaNotice = () => {
	const { t } = useTranslation();

	return (
		<StyledAuthMediaLayoutMediaNotice>
			{t('auth.media.notice')}:
			<StyledAuthMediaLayoutBrands>
				<LogoDerTourInt className="svg-logo-dertour-international" />
				<LogoCleverTours className="svg-logo-clevertour" />
			</StyledAuthMediaLayoutBrands>
		</StyledAuthMediaLayoutMediaNotice>
	);
};
