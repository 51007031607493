import { useMutation } from '@tanstack/react-query';

import { post } from '@src/api/internalApi';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import { IReCaptchaActionType } from '@components/ReCaptchaBox/types';

export const useValidateReCaptcha = () => {
	const { api } = useRoutingContext();

	const {
		isLoading,
		data,
		error,
		mutateAsync: validateReCaptcha,
	} = useMutation(
		['validate-recaptcha'],
		(data: { token: string; action: IReCaptchaActionType }) => post(api.validateReCaptcha(), data),
		{
			retry: false,
		},
	);

	return {
		isLoading,
		data,
		error,
		validateReCaptcha,
	};
};
