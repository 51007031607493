import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import {
	StyledAccordion,
	StyledAccordionBody,
	StyledAccordionBodyInner,
	StyledAccordionHead,
} from '@ui/Accordion/styles';

interface AccordionProps {
	title: string;
	children: any;
	isExpanded?: boolean;
	initialHeight?: number;
}

const Accordion = ({ title, children, isExpanded = true, initialHeight = 0 }: AccordionProps) => {
	const [height, setHeight] = useState(initialHeight);
	const [isActive, setIsActive] = useState(isExpanded);

	const theme = useTheme();
	const elementRef = useRef<HTMLElement>(null);

	const { chevronDown: IconChevronDown } = theme.icon;

	useEffect(() => {
		if (elementRef?.current) {
			setHeight(isActive ? elementRef.current.scrollHeight : initialHeight);
		}
	}, [isActive, elementRef, initialHeight]);

	return (
		<StyledAccordion>
			<StyledAccordionHead isActive={isActive} onClick={() => setIsActive(!isActive)}>
				{title}
				<span>
					<IconChevronDown width={24} height={24} />
				</span>
			</StyledAccordionHead>

			<StyledAccordionBody height={height} ref={elementRef} data-testid="accordion-body">
				<StyledAccordionBodyInner>{children}</StyledAccordionBodyInner>
			</StyledAccordionBody>
		</StyledAccordion>
	);
};

export default Accordion;
