import { createContext, PropsWithChildren, useContext, useState } from 'react';

import ToastRenderer from '@ui/Toast/ToastRenderer';
import { IAddToast, IToast, IToastContextValue } from '@ui/Toast/types';

const ToastContext = createContext<IToastContextValue>({
	items: [],
	showToast: () => {},
	removeToast: () => {},
});

const ToastProvider = ({ children }: PropsWithChildren) => {
	const [items, setItems] = useState<IToast[]>([]);

	const showToast = ({ position, ...toast }: IAddToast) => {
		setItems(prevToasts => [
			...prevToasts,
			{
				id: Date.now().toString(),
				position: position ?? 'top',
				...toast,
			},
		]);
	};

	const removeToast = (id: string) => {
		setItems(prevToasts => prevToasts.filter(toast => toast.id !== id));
	};

	return (
		<ToastContext.Provider
			value={{
				items,
				showToast,
				removeToast,
			}}
		>
			<ToastRenderer />

			{children}
		</ToastContext.Provider>
	);
};

export const useToastContext = () => useContext(ToastContext);

export default ToastProvider;
