import styled, { css, DefaultTheme } from 'styled-components';
import { colorToString } from '@dto/themes/types/color';
import { spacingToString } from '@dto/themes/types';
import { IAlertVariant } from '@ui/AlertBlock/AlertBlock';

const getStylesByVariant = ({ variant, theme }: { variant: IAlertVariant; theme: DefaultTheme }) => {
	const variantStatuses = {
		success: {
			backgroundColor: theme.variant.default.color.background.success,
			borderColor: theme.variant.default.color.border.success,
			color: theme.variant.default.color.icon.success,
		},
		info: {
			backgroundColor: theme.variant.default.color.background.message,
			borderColor: theme.variant.default.color.border.message,
			color: theme.variant.default.color.icon.message,
		},
		error: {
			backgroundColor: theme.variant.default.color.background.critical,
			borderColor: theme.variant.default.color.border.critical,
			color: theme.variant.default.color.icon.critical,
		},
	};

	return variantStatuses[variant];
};

export const StyledAlertBlock = styled.div.attrs((props: { variant: IAlertVariant }) => props)`
	position: relative;
	border: 1px solid transparent;
	border-radius: 8px;
	padding: ${props => spacingToString(props.theme.variant.default.spacing.s3.default)};
	gap: ${props => spacingToString(props.theme.variant.default.spacing.s2.default)};
	display: flex;
	white-space: pre-line;

	${props => {
		const { variant, theme } = props;
		const variantOptions = getStylesByVariant({
			variant,
			theme,
		});

		return (
			variant &&
			css`
				background-color: ${colorToString(variantOptions.backgroundColor)};
				border-color: ${colorToString(variantOptions.borderColor)};
				color: ${colorToString(variantOptions.color)};
			`
		);
	}}
`;

export const StyledAlertBlockIcon = styled.div`
	display: flex;
	align-items: center;
	color: currentColor;
	font-size: 25px;
	line-height: 0;
`;

export const StyledAlertBlockContent = styled.div`
	color: ${props => colorToString(props.theme.variant.default.color.text.copy)};
`;

export const StyledAlertBlockTitle = styled.h3`
	font-size: 16px;
`;

export const StyledAlertBlockDescription = styled.div`
	font-size: 16px;
	line-height: 1.5;
	text-align: left;

	a {
		font-weight: 600;
		color: ${props => colorToString(props.theme.variant.default.color.text.copy)};

		&:hover {
			text-decoration: none;
		}
	}
`;

export const StyledAlertBlockButton = styled.button`
	appearance: none;
	border: none;
	border-radius: 0;
	background: transparent;
	width: 24px;
	height: 24px;
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	order: 3;
	z-index: 10;
	flex-shrink: 0;
	transition: color 0.3s;

	path {
		fill: currentColor;
	}

	&:hover {
		color: currentColor;
	}
`;
