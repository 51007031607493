import styled from 'styled-components';
import { device } from '@src/utilities/media';
import { colorToString } from '@dto/themes/types';
import { StyledMediaRiboon } from '@components/MediaRibbon/styles';
import { StyledAuth, StyledAuthContent } from '@pages/Auth/styles';

export const StyledAuthMediaLayout = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 100%;
	background: linear-gradient(201deg, rgba(228, 0, 40, 0.26) 0%, rgba(255, 253, 251, 1) 43%);
	padding: 20px;

	${StyledAuth},
	${StyledAuthContent} {
		padding: 0;
		background: none;
		box-shadow: none;
	}

	@media ${device.mobile} {
		padding: 30px 20px;
	}
`;

export const StyledAuthMediaLayoutInner = styled.div`
	box-shadow: 0px 8px 16px 0px rgba(39, 28, 26, 0.16), 0px 2px 4px 0px rgba(39, 28, 26, 0.04);
	background: var(--background-bright, #fff);
	border-radius: 8px;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	width: 100%;
	max-width: 1100px;

	@media ${device.tablet} {
		flex-direction: column;
		max-width: 500px;
	}
`;

export const StyledAuthMediaLayoutContent = styled.div<{ wide?: boolean }>`
	max-width: 450px;
	width: 100%;
	padding: 32px 35px 48px;
	text-align: center;
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	flex-direction: column;

	@media ${device.tablet} {
		max-width: 100%;
	}

	@media ${device.mobile} {
		padding: 32px 16px;
	}
`;

export const StyledAuthMediaLayoutMedia = styled.div`
	flex-grow: 1;
	max-width: calc(100% - 450px);
	background: ${props => colorToString(props.theme.variant.default.color.background.neutral.n1)};
	padding: 26px 0 32px 35px;
	max-height: 600px;
	display: flex;
	flex-direction: column;

	@media ${device.tablet} {
		order: -1;
		max-height: none;
		max-width: 100%;
		padding: 25px 0 25px 35px;
	}

	@media ${device.mobile} {
		padding: 25px 20px;
	}

	${StyledMediaRiboon} {
		margin: 0 15px 20px -35px;

		@media ${device.mobile} {
			margin-left: -20px;
		}
	}
`;

export const StyledAuthMediaLayoutMediaBar = styled.div``;
