import { useQuery } from '@tanstack/react-query';

import { get } from '@src/api/internalApi';
import { FaqsResponse } from '@src/types/legal';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';

const useGetFaqs = () => {
	const { api } = useRoutingContext();

	const {
		isLoading,
		data: faqsData,
		error,
	} = useQuery(['Faqs'], () => get<FaqsResponse>(api.faqs()), {
		retry: false,
	});

	return {
		isLoading,
		faqsData,
		error,
	};
};

export default useGetFaqs;
