import { useQuery } from '@tanstack/react-query';

import { get } from '@src/api/internalApi';
import { RightOfWithdrawalResponse } from '@src/types/legal';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';

const useGetRightOfWithdrawal = () => {
	const { api } = useRoutingContext();

	const {
		isLoading,
		data: rightOfWithdrawalData,
		error,
	} = useQuery(['RightOfWithdrawal'], () => get<RightOfWithdrawalResponse>(api.legalRightOfWithdrawal()), {
		retry: false,
	});

	return {
		isLoading,
		rightOfWithdrawalData,
		error,
	};
};

export default useGetRightOfWithdrawal;
