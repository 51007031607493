import { useQuery } from '@tanstack/react-query';

import { get } from '@src/api/internalApi';
import { TermsOfUseResponse } from '@src/types/legal';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import useInvalidateQueries from '@src/api/hooks/useInvalidateQueries';

const useGetTermsOfUse = (options?: { shouldInvalidate?: boolean }) => {
	const { invalidate } = useInvalidateQueries();
	const { api } = useRoutingContext();

	const {
		isLoading,
		data: termsOfUseData,
		error,
	} = useQuery(
		['TermsOfUse'],
		async () => {
			try {
				const response = await get<TermsOfUseResponse>(api.legalTermsOfUse());

				if (options?.shouldInvalidate) {
					await invalidate(['me']);
				}

				return response;
			} catch (e) {
				return Promise.reject(e);
			}
		},
		{
			cacheTime: 0,
			staleTime: 0,
			retry: false,
		},
	);

	return {
		isLoading,
		termsOfUseData,
		error,
	};
};

export default useGetTermsOfUse;
