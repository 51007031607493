import { Fragment } from 'react';
import { Outlet, ScrollRestoration as ReactScrollRestoration } from 'react-router-dom';

export const ScrollRestoration = () => {
	return (
		<Fragment>
			<ReactScrollRestoration />

			<Outlet />
		</Fragment>
	);
};
