import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import usePortal from '@src/hooks/usePortal';

type ModalPortalProps = {
	id: string;
	children: ReactNode;
};

const ModalPortal = ({ id, children }: ModalPortalProps) => {
	const target = usePortal(id);

	return createPortal(children, target);
};

export default ModalPortal;
