import styled, { css } from 'styled-components';
import { colorToString } from '@dto/themes/types/color';

export const StyledCheckboxLabel = styled.label`
	display: flex;
	gap: 10px;
	align-items: flex-start;
	cursor: pointer;
	user-select: none;
`;

export const StyledCheckboxText = styled.span`
	display: block;
	line-height: 1.2;

	a {
		color: inherit;
		font-weight: 600;

		&:hover {
			&,
			u {
				text-decoration: none;
			}
		}
	}
`;

export const StyledCheckboxIcon = styled.span<{ hasError?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	padding: 1px;
	border: 2px solid
		${props =>
			props.hasError
				? colorToString(props.theme.variant.default.color.icon.critical)
				: colorToString(props.theme.variant.default.color.icon.interactive.secondary)};
	border-radius: 4px;
	background-color: #fff;
	color: #32be53;

	svg {
		opacity: 0;
		transition: opacity 0.3s;
	}
`;

export const StyledCheckbox = styled.div<{ clickableText?: boolean }>`
	position: relative;

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 1px;
		height: 1px;
		opacity: 0;

		&:focus + label ${StyledCheckboxIcon} {
			border-color: ${props => colorToString(props.theme.variant.default.color.focus.ring)};
		}

		&:checked + label svg {
			opacity: 1;
		}

		&:disabled + label {
			opacity: 0.5;
			cursor: default;
		}
	}

	${props =>
		props?.clickableText &&
		css`
			display: flex;
			align-items: center;

			label {
				margin-right: 10px;
			}
		`}
`;
