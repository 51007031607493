import '@src/utilities/tracing';
import { createRoot } from 'react-dom/client';
import '@src/utilities/yup-custom';

import App from '@src/instances/app/App';

const node: HTMLElement | null = document.getElementById('root') || document.createElement('div');
const root = createRoot(node);

root.render(<App />);
