import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthEmailVerifyFormValidationSchema } from '@pages/Auth/LandingAuth/auth-email-verify.schema';
import { IReCaptchaActionType } from '@components/ReCaptchaBox/types';
import { IAuthVerifyFormData } from '@src/types/auth';
import { useValidatedForm } from '@src/hooks/useValidatedForm';
import { useVerification } from '@src/api/hooks/auth/useVerification';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import { useNotificationNavigate } from '@pages/Notification/useNotification';

import { AuthLayout } from '@pages/Auth/AuthLayout/AuthLayout';
import ValidatedFormInput from '@components/ValidatedFormInput/ValidatedFormInput';
import ReCaptchaBox from '@components/ReCaptchaBox/ReCaptchaBox';
import BaseButton from '@ui/BaseButton/BaseButton';
import Spinner from '@ui/Spinner/Spinner';

import { useAdobeAnalytics } from '@src/contexts/AdobeAnalytics/AdobeAnalyticsContext';
import { useUsercentricsEffectOnce } from '@src/hooks/useUsercentricsEffectOnce';

import EnvelopeSendIllustration from '@assets/images/envelope-send.svg';
import {
	StyledAuthAction,
	StyledAuthActions,
	StyledAuthEntry,
	StyledAuthImage,
	StyledAuthItem,
	StyledAuthTitle,
} from '@pages/Auth/styles';
import { useNavigateWithQueryParamsPersistence } from '@src/hooks/useNavigateWithQueryParamsPersistence';
import { ConfigService } from '@src/config/ConfigService';

export const ExpiredVerificationLink = () => {
	const { t } = useTranslation();
	const { client } = useRoutingContext();
	const { navigate } = useNavigateWithQueryParamsPersistence();
	const { notificationNavigate } = useNotificationNavigate();
	const { isLoading, verifyCustomer } = useVerification();
	const { dataLayer } = useAdobeAnalytics();

	useUsercentricsEffectOnce(() => dataLayer.pageView('entry'));

	const { ValidatedFormProvider, methods } = useValidatedForm<Pick<IAuthVerifyFormData, 'email' | 'isValidRecaptcha'>>({
		schema: AuthEmailVerifyFormValidationSchema,
		options: {
			defaultValues: {
				email: '',
				isValidRecaptcha: !ConfigService.isRecaptchaEnabled,
			},
		},
		handleFormSubmit: async data => {
			const { existingAccount } = await verifyCustomer({
				email: data.email,
			});

			if (existingAccount) {
				navigate(client.login(), {
					state: {
						email: data.email,
					},
				});
			} else {
				notificationNavigate('RegisterCheckYourEmail');
			}
		},
	});

	const validRecaptcha = methods.getValues('isValidRecaptcha');

	return (
		<AuthLayout
			showBrandLogos={false}
			image={<StyledAuthImage src={EnvelopeSendIllustration} />}
			header={
				<Fragment>
					<StyledAuthTitle size="small" textAlign="center">
						{t('auth.expiredVerificationLink.title')}
					</StyledAuthTitle>

					<StyledAuthEntry>{t('auth.expiredVerificationLink.entry')}</StyledAuthEntry>
				</Fragment>
			}
		>
			<ValidatedFormProvider>
				<StyledAuthItem>
					<ValidatedFormInput localeGroup="auth" name="email" icon="envelope" />
				</StyledAuthItem>

				<StyledAuthActions>
					{!validRecaptcha && (
						<ReCaptchaBox widgetId="recaptcha-auth" action={IReCaptchaActionType.verifyEmail} name="isValidRecaptcha" />
					)}

					<StyledAuthAction>
						<BaseButton type="submit">
							{isLoading ? <Spinner width={20} height={20} isWhite /> : t('auth.expiredVerificationLink.link')}
						</BaseButton>
					</StyledAuthAction>
				</StyledAuthActions>
			</ValidatedFormProvider>
		</AuthLayout>
	);
};
