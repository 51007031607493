import { useMutation } from '@tanstack/react-query';

import { post } from '@src/api/internalApi';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import { IRegisterCredentials } from '@src/types/auth';

export const useSignup = () => {
	const { api } = useRoutingContext();

	const {
		isLoading,
		data,
		error,
		mutateAsync: registerCustomer,
	} = useMutation(['register/customer'], (data: IRegisterCredentials) => post<void>(api.signup(), data), {
		retry: false,
	});

	return {
		isLoading,
		data,
		error,
		registerCustomer,
	};
};
