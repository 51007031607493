import styled from 'styled-components';

export const StyledWrapper = styled.div`
	width: 100%;
	overflow: hidden;
	display: flex;
`;

export const StyledWrapperInner = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`;

export const StyledMain = styled.main`
	flex-grow: 1;
	display: flex;
	max-width: 100%;
`;
