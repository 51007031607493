import styled from 'styled-components';

export const StyledPageLink = styled.button`
	appearance: none;
	border: none;
	border-radius: 0;
	background: transparent;
	font: inherit;
	text-decoration: underline;

	&:hover {
		cursor: pointer;
		text-decoration: none;
	}
`;
