import { useQuery } from '@tanstack/react-query';

import { get } from '@src/api/internalApi';
import { ImprintResponse } from '@src/types/legal';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';

const useGetImprint = () => {
	const { api } = useRoutingContext();

	const {
		isLoading,
		data: imprintData,
		error,
	} = useQuery(['Imprint'], () => get<ImprintResponse>(api.legalImprint()), {
		retry: false,
	});

	return {
		isLoading,
		imprintData,
		error,
	};
};

export default useGetImprint;
