import { useQuery } from '@tanstack/react-query';

import { get } from '@src/api/internalApi';
import { PrivacyResponse } from '@src/types/legal';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import useInvalidateQueries from '@src/api/hooks/useInvalidateQueries';

const useGetPrivacy = (options?: { shouldInvalidate?: boolean }) => {
	const { invalidate } = useInvalidateQueries();
	const { api } = useRoutingContext();
	const {
		isLoading,
		data: privacyData,
		error,
	} = useQuery(
		['Privacy'],
		async () => {
			try {
				const response = await get<PrivacyResponse>(api.legalPrivacy());

				if (options?.shouldInvalidate) {
					await invalidate(['me']);
				}

				return response;
			} catch (e) {
				return Promise.reject(e);
			}
		},
		{
			cacheTime: 0,
			staleTime: 0,
			retry: false,
		},
	);

	return {
		isLoading,
		privacyData,
		error,
	};
};

export default useGetPrivacy;
