import { ReactNode, useEffect } from 'react';
import { useTheme } from 'styled-components';
import ModalPortal from '@ui/BaseModal/ModalPortal';
import {
	StyledModalWrapper,
	StyledModalDialog,
	StyledModalContent,
	StyledModalButtonClose,
	StyledModalHead,
} from './styles';

interface BaseModalProps {
	id?: string;
	isOpen: boolean;
	onClose: () => void;
	title?: string;
	children: ReactNode;
	contentWidth?: string;
}

const BaseModal = ({ id = 'modal-root', isOpen, onClose, title, contentWidth, children }: BaseModalProps) => {
	const theme = useTheme();
	const { close: IconClose } = theme.icon;

	useEffect(() => {
		const escapeClose = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				onClose();
			}
		};
		window.addEventListener('keydown', escapeClose);
		return () => window.removeEventListener('keydown', escapeClose);
	}, [onClose]);

	if (!isOpen) {
		return null;
	}

	return (
		<ModalPortal id={id}>
			<StyledModalWrapper onClick={onClose} data-testid="modal-wrapper">
				<StyledModalDialog onClick={event => event.stopPropagation()} modalWidth={contentWidth}>
					<StyledModalHead>
						{title && <h2>{title}</h2>}

						<StyledModalButtonClose data-testid="modal-close-btn" onClick={onClose}>
							<IconClose width={24} height={24} />
						</StyledModalButtonClose>
					</StyledModalHead>

					<StyledModalContent>{children}</StyledModalContent>
				</StyledModalDialog>
			</StyledModalWrapper>
		</ModalPortal>
	);
};

export default BaseModal;
