import { Fragment, useMemo } from 'react';

import { IToast, ToastPositionsType } from '@ui/Toast/types';
import { useToastContext } from '@ui/Toast/ToastContext';
import ToastGroupItems from '@ui/Toast/ToastGroupItems';

import { StyledToastGroup } from '@ui/Toast/styles';

interface IToastGroup {
	position: ToastPositionsType;
	items: IToast[];
}

const ToastRenderer = () => {
	const { items } = useToastContext();
	const positions = useMemo(
		() =>
			Object.values(
				items.reduce((groups, item) => {
					const position = item.position;

					if (!groups[position]) {
						groups[position] = {
							position: position,
							items: [],
						};
					}

					groups[position].items.push(item);

					return groups;
				}, {} as Record<ToastPositionsType, IToastGroup>),
			),
		[items],
	);

	return (
		<Fragment>
			{positions.map(pos => (
				<StyledToastGroup key={pos.position} position={pos.position}>
					<ToastGroupItems items={pos.items} />
				</StyledToastGroup>
			))}
		</Fragment>
	);
};

export default ToastRenderer;
