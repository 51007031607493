import { useTranslation } from 'react-i18next';

import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import { useUsercentricsContext } from '@src/contexts/UsercentricsContext/UsercentricsContext';

import TenantLogo from '@assets/images/dertour/logo-footer.svg';
import { StyledFooter, StyledFooterNavLink, StyledFooterNavButton } from '@components/BaseFooter/styles';
import StyledShell from '@ui/StyledShell/StyledShell';

const BaseFooter = () => {
	const { t } = useTranslation();
	const { client } = useRoutingContext();
	const { showSecondLayer, isInitialized } = useUsercentricsContext();

	return (
		<StyledFooter data-testid="footer">
			<StyledShell>
				<ul>
					<li>
						<StyledFooterNavLink to={client.imprint()} role="link">
							{t('footer.links.imprint')}
						</StyledFooterNavLink>
					</li>

					<li>
						<StyledFooterNavLink to={client.privacyPolicy()} role="link">
							{t('footer.links.privacyPolicy')}
						</StyledFooterNavLink>
					</li>

					<li>
						<StyledFooterNavButton
							onClick={() => isInitialized && showSecondLayer()}
							disabled={!isInitialized}
							role="button"
						>
							{t('footer.links.cookiePolicy')}
						</StyledFooterNavButton>
					</li>

					<li>
						<StyledFooterNavLink to={client.termsOfUse()} role="link">
							{t('footer.links.termsOfUse')}
						</StyledFooterNavLink>
					</li>

					<li>
						<StyledFooterNavLink to={client.faqs()} role="link">
							{t('footer.links.faqs')}
						</StyledFooterNavLink>
					</li>
				</ul>

				<img src={TenantLogo} alt="Dertour" width={105} height={60} />
			</StyledShell>
		</StyledFooter>
	);
};

export default BaseFooter;
