import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export function useUpdateEffect(callback: EffectCallback, deps: DependencyList) {
	const renderCycleRef = useRef(false);
	const effectCycleRef = useRef(false);

	useEffect(() => {
		const mounted = renderCycleRef.current;
		const run = mounted && effectCycleRef.current;

		if (run) {
			return callback();
		}

		effectCycleRef.current = true;
	}, deps);

	useEffect(() => {
		renderCycleRef.current = true;

		return () => {
			renderCycleRef.current = false;
		};
	}, []);
}
